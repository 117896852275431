import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';

import {CardActionType, MultiGrid} from 'components/MultiGrid';
import PageMetaData from 'components/PageMetaData';
import * as DashboardStore from 'stores/Dashboard';

@withRouter
@connect((state) => ({dashboard: state.dashboard}), DashboardStore.actionCreators)
export default class Approvals extends React.Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        ...DashboardStore.ActionShape,
        dashboard: DashboardStore.StateShape,
    };
    
    constructor(props) {
        super(props);

        this.state = {
            gridName: 'approvalColumns',
            columns: [],
            view: 'Grid',
            approvals: [],
            loading: true,
        }
    }

    componentDidMount() {
        const {gridName} = this.state;
        axios.get(`/ShoppingService/api/v1/account/gridSetting/${gridName}`).then(x => {
            this.setState({columns: x.data.columns, view: x.data.view});
        });
        this.getData();
    }

    getData() {
        const {dashboard: {myAccountFilter}, resetMyAccountFilter} = this.props;
        this.setState({loading: true});
        let request = {
            lineItemIds: myAccountFilter.lineItemIds,
        };
        axios.post('/ShoppingService/api/v1/approval', request).then(x => {
            let data = x.data;
            data.forEach(x => {
                x.price = x.price.toFixed(2);
                x.extendedPrice = x.extendedPrice.toFixed(2);
            });
            if (myAccountFilter.lineItemIds) resetMyAccountFilter();
            this.setState({approvals: data, loading: false});
        });
    }

    onRefresh() {
        this.getData();
    }

    onSelect(item) {
        const {history} = this.props;
        history.push(`/order/${item.lineItemId}`);
    }

    render() {
        const {gridName, columns, view, approvals, loading} = this.state;

        let selectionMode = approvals.every(x => x.isServiceItem) || approvals.every(x => !x.isServiceItem) ? 'multiple' : 'checkbox';

        return (<div>
            <PageMetaData 
                title="Approvals" 
                pageType="other" 
                trackAnalytics={true} />
            <MultiGrid
                gridName={gridName}
                view={view}
                cardAction={CardActionType.AwaitingApproval}
                data={approvals}
                columns={columns}
                label="My Account"
                subLabel="Approvals"
                selectionMode={selectionMode}
                onRefresh={::this.onRefresh}
                onSelect={::this.onSelect}
                loadingData={loading}
            />
        </div>);
    }
}
